<script setup lang="ts">
import { computed } from 'vue';
import { RouterView, useRoute } from 'vue-router';
import HeaderComponent from '@/components/Header/HeaderComponent.vue';
import { getFunctionsToFixBodyForModal, useSetDeviceHeight, useSetMediaQuery } from '@/utils/deviceUtils';
import TheFooter from '@/components/TheFooter/TheFooter.vue';
import { useUserAccount } from '@/stores/userAccount';

const { addCalculateScroll } = getFunctionsToFixBodyForModal();

const route = useRoute();

const userAccountStore: {
  getIsUserAccountLoading: boolean;
  fetchUserAccount: () => Promise<void>;
  isUserActive: boolean;
} = useUserAccount();

const layoutContentClass = computed(() => {
  return ['layout-content', { 'full-height-exclude-header': route.name !== 'not-found' && (route.path === '/account' || userAccountStore.isUserActive) }];
});

useSetMediaQuery();
useSetDeviceHeight();
addCalculateScroll();
</script>

<template>
  <div class="layout">
    <HeaderComponent class="layout-header" />
    <div :class="layoutContentClass">
      <RouterView v-show="!userAccountStore.getIsUserAccountLoading" :key="route.path" />
    </div>
    <TheFooter v-show="!userAccountStore.getIsUserAccountLoading" class="layout-footer" />
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/scss/mixins.scss';

.layout {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh); // если не поддерживается строчка ниже
  min-height: calc((var(--vh, 1vh) * 100)); // для мобильных устройств
}

.header.position-fixed + .layout-content {
  top: $headerHeight;
}

.layout-content {
  position: relative;
  flex: 1 1 auto;
  flex-direction: column;
  display: flex;

  > * {
    margin-top: $headerHeight;
  }
}
</style>
