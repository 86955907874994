<script lang="ts">
export default {
  name: 'MainView',
};
</script>

<script setup lang="ts">
import { onBeforeMount } from 'vue';
import { useNewsStore } from '@/stores/news';
import NewsVerticalItemsList from '@/components/News/NewsVerticalItemsList.vue';
import TheMainNews from '@/components/News/TheMainNews.vue';

const mainPageStore = useNewsStore();

onBeforeMount(async () => {
  await mainPageStore.fetchMainPageData();
});
</script>
<template>
  <div class="the-main-page full-height-exclude-header">
    <template v-if="mainPageStore?.mainPage">
      <TheMainNews :newsItem="mainPageStore.mainPage.mainNews" />
      <NewsVerticalItemsList :newsItems="mainPageStore.mainPage.lastNews" />
    </template>
  </div>
</template>

<style scoped lang="scss"></style>
