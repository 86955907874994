import type { Rule } from 'ant-design-vue/es/form';

export const createBackendValidator = (message: string) => {
  return async (_rule?: Rule, value?: string) => {
    return Promise.reject(message);
  };
};

export const formValidationRules = {
  email: [
    { required: true, message: 'Пожалуйста, укажите email' },
    { type: 'email', message: 'Пожалуйста, укажите корректный email' },
  ],
  password: [{ required: true, message: 'Пожалуйста, укажите пароль' }],
  country: [{ required: true, message: 'Пожалуйста, укажите страну' }],
  city: [{ required: true, message: 'Пожалуйста, укажите город' }],
  appeal: [{ required: true, message: 'Пожалуйста, укажите как к вам обращаться' }],
  firstName: [{ required: true, message: 'Пожалуйста, укажите имя' }],
  lastName: [{ required: true, message: 'Пожалуйста, укажите фамилию' }],
  company: [{ required: true, message: 'Пожалуйста, укажите Медиа/Компанию' }],
  phone: [{ required: true, message: 'Пожалуйста, укажите телефон' }],
  text: [{ required: true, message: 'Поле не может быть пустым' }],
};
