<script lang="ts">
export default {
  name: 'BaseImage',
};
</script>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { maxBy } from 'lodash-es';
import type { IImageSize } from '@/types/Iterfaces';

const props = withDefaults(
  defineProps<{
    srcFunc: (width: number) => string;
    width: number;
    height: number;
    alt: string;
    sizes: IImageSize[];
  }>(),
  {
    srcFunc: (width: number) => `https://via.placeholder.com/${width}`,
    width: 370,
    height: 247,
    alt: 'Base image',
    sizes: () => [
      {
        imageWidth: 370,
        screenWidth: 1350,
      },
    ],
  },
);

const isImageLoaded = ref<boolean>(false);

const getSrc = computed(() => {
  return (width: number) => props.srcFunc(width);
});

const maxWidthSize = computed<{ imageWidth: number; screenWidth: number }>(() => {
  return (
    maxBy(props.sizes, function (o) {
      return o.screenWidth;
    }) || { imageWidth: props.width, screenWidth: props.width }
  );
});

const getSrcsetObj = (size: { imageWidth: number; screenWidth: number }) => {
  return {
    srcSetString: `${props.srcFunc(size.imageWidth)},
        ${props.srcFunc(size.imageWidth * 1.5)} 1.5x,
        ${props.srcFunc(size.imageWidth * 2)} 2x`,
    media: `(max-width: ${size.screenWidth}px)`,
  };
};

const onImageLoad = () => {
  isImageLoaded.value = true;
};
</script>

<template>
  <div>
    <picture v-show="isImageLoaded">
      <source
        :key="size.screenWidth + size.imageWidth"
        v-for="size in props.sizes"
        :srcset="getSrcsetObj(size).srcSetString"
        :media="getSrcsetObj(size).media"
      />
      <source
        :srcset="getSrcsetObj(maxWidthSize).srcSetString"
        :media="`(min-width: ${maxWidthSize.screenWidth + 1}px)`"
      />
      <img
        class="base-image"
        :style="{ '--width': `${props.width}`, '--height': `${props.height}` }"
        :src="getSrc(props.width)"
        :alt="props.alt"
        @load="onImageLoad"
      />
    </picture>
    <img
      v-if="!isImageLoaded"
      class="base-image base-image-placeholder"
      :src="getSrc(40)"
      :alt="props.alt"
      loading="lazy"
      :style="{ '--width': `${props.width}`, '--height': `${props.height}` }"
    />
    <slot></slot>
  </div>
</template>

<style scoped lang="scss">
.base-image {
  aspect-ratio: calc(var(--width) / var(--height));
  display: block;
  height: auto;
  width: 100%;
  transition: filter 0.3s linear;
  filter: blur(0);
}

.base-image-placeholder {
  filter: blur(6px);
}
</style>
