import { MetaDataClass, MainPageClass, NewsItemClass, NewsItemPageClass } from '@/Models/News';
import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import { API_URL, axiosInstance } from '@/api/axios-instance';
import type { TAllNewsResponse, IMainPageData, INewsItemPageData, TNewsFilesResponse, TMetaData } from '@/Models/News';
import { FileClass } from '@/Models/File';
import { useBasketStore } from '@/stores/basket';
import type { IBasketStore } from '@/stores/basket';

interface IFetchAllNewsParams {
  page?: number;
  tags?: string | string[];
  year?: number;
  month?: number;
}

export interface INewsStore {
  allNews: NewsItemClass[] | [];
  fetchAllNews: (params: IFetchAllNewsParams) => void;
  fetchNextNews: (params: IFetchAllNewsParams) => void;
  isNextNewsFetching: boolean;
  allNewsMeta: MetaDataClass;
}

export const useNewsStore = defineStore('news', () => {
  const mainPage = ref<MainPageClass | undefined>();
  const allNews = ref<NewsItemClass[] | []>([]);
  const allNewsMeta = ref<MetaDataClass>({
    count: 0,
    next: null,
    previous: null,
  } as MetaDataClass);
  const isAllNewsLoading = ref<boolean>(false);
  const isNextNewsFetching = ref<boolean>(false);

  const fetchMainPageData = async () => {
    try {
      const { data } = await axiosInstance.get<IMainPageData>(API_URL.MAIN_PAGE);
      mainPage.value = new MainPageClass(data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchAllNews = async (params: IFetchAllNewsParams = { page: 1 }): Promise<void> => {
    try {
      isAllNewsLoading.value = true;
      params.page = params.page ? params.page : 1;
      if (Array.isArray(params?.tags)) {
        params.tags = params.tags.join(',');
      }
      const newsPagesRequests = [];
      for (let i = 1; i <= params.page; i++) {
        const paramsForRequest = { ...params, page: i };
        newsPagesRequests.push(axiosInstance.get<TAllNewsResponse>(API_URL.NEWS, { params: paramsForRequest }));
      }
      const data = (await Promise.all(newsPagesRequests)).map(({ data }) => data);
      allNews.value = data.reduce((acc: NewsItemClass[], currentValue) => {
        acc = [...acc, ...currentValue.results.map((news) => new NewsItemClass(news))];
        return acc;
      }, []);
      allNewsMeta.value = new MetaDataClass({
        count: data[data.length - 1].count,
        next: data[data.length - 1].next,
        previous: data[data.length - 1].previous,
      });
    } catch (error) {
      console.error(error);
    } finally {
      isAllNewsLoading.value = false;
    }
  };

  const fetchNextNews = async (params: IFetchAllNewsParams = {}) => {
    if (!allNewsMeta.value.next) {
      return;
    }

    try {
      isNextNewsFetching.value = true;
      const { data } = await axiosInstance.get<TAllNewsResponse>(API_URL.NEWS, {
        params: { ...params, page: allNewsMeta.value.next },
      });
      allNews.value = [...allNews.value, ...data.results.map((newsItem) => new NewsItemClass(newsItem))];
      allNewsMeta.value = new MetaDataClass(data);
    } catch (error) {
      console.error(error);
    } finally {
      isNextNewsFetching.value = false;
    }
  };

  return {
    mainPage,
    allNews,
    allNewsMeta,
    isNextNewsFetching,
    fetchMainPageData,
    fetchAllNews,
    fetchNextNews,
    isAllNewsLoading,
  };
});

export interface INewsPageStore {
  newsItemPage: NewsItemPageClass | undefined;
  fetchNewsById: (id: string) => Promise<void>;
  fetchNewsFiles: (params: { id: string | null; page: number }) => Promise<void>;
  fetchNextNewsFiles: (id: string) => Promise<void>;
  files: FileClass[] | [];
  filesMeta: TMetaData;
  notInBasketFiles: FileClass[];
  addAllFilesToBasket: () => Promise<void>;
  isNextNewsFilesFetching: boolean;
}

export const useNewsItemPage = defineStore('news-page', () => {
  const newsItemPage = ref<NewsItemPageClass | undefined>();
  const files = ref<FileClass[] | []>([]);
  const filesMeta = ref<TMetaData>({
    count: 0,
    next: null,
    previous: null,
  });
  const isNewsFilesLoading = ref<boolean>(false);
  const isNextNewsFilesFetching = ref<boolean>(false);
  const basketStore: IBasketStore = useBasketStore();

  const fetchNewsById = async (id: string) => {
    try {
      const { data } = await axiosInstance.get<INewsItemPageData>(`${API_URL.NEWS}${id}`);
      newsItemPage.value = new NewsItemPageClass(data);
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  };

  const fetchNewsFiles = async (params: { id: string | null; page: number } = { page: 1, id: null }) => {
    if (!params.id) {
      return;
    }

    try {
      isNewsFilesLoading.value = true;
      const newsPagesRequests = [];
      for (let i = 1; i <= params.page; i++) {
        newsPagesRequests.push(
          axiosInstance.get<TNewsFilesResponse>(`${API_URL.NEWS}${params.id}/files`, { params: { page: i } }),
        );
      }
      const data = (await Promise.all(newsPagesRequests)).map(({ data }) => data);
      files.value = data.reduce((acc: FileClass[], currentValue) => {
        acc = [...acc, ...currentValue.results.map((file) => new FileClass(file))];
        return acc;
      }, []);
      filesMeta.value = {
        count: data[data.length - 1].count,
        next: data[data.length - 1].next,
        previous: data[data.length - 1].previous,
      };
    } catch (error) {
      console.error(error);
    } finally {
      isNewsFilesLoading.value = false;
    }
  };

  const fetchNextNewsFiles = async (id: string) => {
    if (!filesMeta.value.next) {
      return;
    }

    try {
      isNextNewsFilesFetching.value = true;
      const { data } = await axiosInstance.get<TNewsFilesResponse>(`${API_URL.NEWS}${id}/files`, {
        params: { page: filesMeta.value.next },
      });
      files.value = [...files.value, ...data.results.map((file) => new FileClass(file))];
      filesMeta.value = {
        count: data.count,
        next: data.next,
        previous: data.previous,
      };
    } catch (error) {
      console.error(error);
    } finally {
      isNextNewsFilesFetching.value = false;
    }
  };

  const addAllFilesToBasket = async () => {
    const innerFiles = [...notInBasketFiles.value];
    try {
      const { data } = await basketStore.addBatchToBasket(innerFiles.map(el => el.id));
      files.value.forEach(file => {
        if (data.added_files?.find(el => el[`${file.id}`] === 'ok')) {
          file.inBasket = true;
        }
      });
      await basketStore.fetchBasket();
    } catch (error) {
      console.error(error);
    }
  };

  const notInBasketFiles = computed((): FileClass[] => {
    return files.value.filter((file) => !file.inBasket);
  });

  return {
    newsItemPage,
    files,
    filesMeta,
    isNextNewsFilesFetching,
    fetchNewsById,
    fetchNewsFiles,
    fetchNextNewsFiles,
    addAllFilesToBasket,
    notInBasketFiles,
  };
});
