import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import { useUserAccount } from '@/stores/userAccount';
import { authorised } from './guards';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView,
    },
    {
      path: '/contacts',
      name: 'contacts',
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../views/ContactsView.vue'),
    },
    {
      path: '/ui-kit',
      name: 'ui-kit',
      component: () => import('../views/UIKitView.vue'),
    },
    {
      path: '/register-success',
      name: 'register-success',
      component: () => import('../views/RegisterSuccessView.vue'),
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('../views/RegisterView.vue'),
    },
    {
      path: '/authorisation',
      name: 'authorisation',
      component: () => import('../views/AuthorisationView.vue'),
    },
    {
      path: '/brand',
      name: 'brand',
      component: () => import('../views/BrandView.vue'),
    },
    {
      path: '/news',
      name: 'news',
      component: () => import('../views/NewsView.vue'),
    },
    {
      path: '/news/:id',
      name: 'news-item',
      component: () => import('../views/NewsItemView.vue'),
    },
    {
      path: '/models/:slug',
      name: 'models-item',
      component: () => import('../views/ModelsItemView.vue'),
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('../views/RegisterView.vue'),
    },
    {
      path: '/password-reset-success',
      name: 'password-reset-success',
      component: () => import('../views/PasswordResetSuccessView.vue'),
    },
    {
      path: '/profile-edit-success',
      name: 'profile-edit-success',
      component: () => import('../views/ProfileEditSuccessView.vue'),
    },
    {
      path: '/basket',
      name: 'basket',
      component: () => import('../views/BasketView.vue'),
    },
    {
      path: '/archiving/:id',
      name: 'archiving',
      component: () => import('../views/ArchivingView.vue'),
    },
    {
      path: '/gallery',
      name: 'media',
      component: () => import('../views/MediaView.vue'),
    },
    {
      path: '/404',
      name: 'not-found',
      component: () => import('../views/NotFoundView.vue'),
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: '404',
    },
    // ToDo убрать когда account/password_reset/confirm будет 302 на profile
    {
      path: '/account',
      name: 'account',
      component: () => import('../views/RegisterView.vue'),
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    const routesToUpdateOnQueryChange = ['news-item'];

    if (from.name === to.name && routesToUpdateOnQueryChange.find(el => el === from.name) && JSON.stringify(from.query) !== JSON.stringify(to.query)) {
      return {
        top: 0,
        behavior: 'smooth',
      };
    }
    if (from.name === to.name && JSON.stringify(from.params) === JSON.stringify(to.params)) {
      return savedPosition || { x: 0, y: document.documentElement.style.getPropertyValue('--scroll-y') };
    }
    return {
      top: 0,
      behavior: 'smooth',
    };
  },
});

router.beforeEach(async (to, from) => {
  if (to.matched.length === 0) {
    return { name: 'not-found' };
  }
  
  const userAccountStore = useUserAccount();
  if (!userAccountStore.isUserCreated) {
    await userAccountStore.fetchUserAccount();
  }

  if (to.name === 'account') {
    return await authorised();
  } else {
    return true;
  }
});

export default router;
