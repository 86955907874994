import { createApp } from 'vue';
import { createPinia } from 'pinia';
import Maska from 'maska';
import 'virtual:vite-plugin-sentry/sentry-config';
import * as Sentry from '@sentry/vue';
import './assets/scss/index.scss';

import App from './App.vue';
import router from './router';

const app = createApp(App);

const dist = import.meta.env.VITE_PLUGIN_SENTRY_CONFIG.dist;
const release = import.meta.env.VITE_PLUGIN_SENTRY_CONFIG.release;

Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  enabled: import.meta.env.MODE === 'stage',
  environment: import.meta.env.MODE,
  dist,
  release,
});

app.use(createPinia());
app.use(Maska);
app.use(router);

app.mount('#app');
