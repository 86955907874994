import type { IFile } from '@/Models/File';
import { FileClass } from '@/Models/File';

export interface ITag {
  id: number;
  name: string;
}

export class TagClass {
  id: number;
  name: string;

  constructor(tagData: ITag) {
    this.id = tagData.id;
    this.name = tagData.name;
  }
}

export interface INewsItem {
  id: number;
  title: string;
  brief_html: string;
  preview: string;
  tags: ITag[];
  pub_date: string;
  link: string;
  body_html?: string;
  link_archive_pdf?: string;
  link_archive_word?: string;
}

export class NewsItemClass {
  id: number;
  title: string;
  briefHtml: string;
  preview: string;
  tags: ITag[];
  pubDate: Date;
  link: string;
  bodyHtml?: string;
  linkPdf?: string | null;
  linkWord?: string | null;

  constructor(newsItemData: INewsItem) {
    this.id = newsItemData.id;
    this.title = newsItemData.title;
    this.briefHtml = newsItemData.brief_html;
    this.preview = newsItemData.preview;
    this.tags = newsItemData.tags.map((tag) => new TagClass(tag));
    this.pubDate = this.convertStringToDate(newsItemData.pub_date);
    this.link = newsItemData.link;
    this.bodyHtml = newsItemData.body_html;
    this.linkPdf = newsItemData.link_archive_pdf;
    this.linkWord = newsItemData.link_archive_word;
  }

  get linkToNewsItemPage() {
    return `/news/${this.id}`;
  }

  get pathToPreview() {
    return this.preview;
  }

  convertStringToDate(str: string = new Date().toString()): Date {
    const pattern = /(\d{2})\.(\d{2})\.(\d{4})/;
    return new Date(str.replace(pattern, '$3-$2-$1'));
  }
}

export interface IMainPageData {
  main_news: INewsItem;
  last_news: INewsItem[];
}

export class MainPageClass {
  mainNews: NewsItemClass;
  lastNews: NewsItemClass[];

  constructor(mainPageData: IMainPageData) {
    this.mainNews = new NewsItemClass(mainPageData.main_news);
    this.lastNews = mainPageData.last_news.map((lastNews) => new NewsItemClass(lastNews));
  }
}

export interface INewsItemPageData extends INewsItem {
  related_news: INewsItem[];
  attachments_limit: IFile[];
}

export class NewsItemPageClass extends NewsItemClass {
  relatedNews: NewsItemClass[];
  attachmentsLimit: FileClass[];

  constructor(newsItemData: INewsItemPageData) {
    super(newsItemData);

    this.relatedNews = newsItemData.related_news.map((news) => new NewsItemClass(news));
    this.attachmentsLimit = newsItemData.attachments_limit.map((file) => new FileClass(file));
  }
}

export type TMetaData = {
  count: number;
  next: number | null;
  previous: number | null;
  currentPage?: number;
};

export class MetaDataClass implements TMetaData {
  count: number;
  next: number | null;
  previous: number | null;

  constructor(data: TMetaData) {
    this.count = data.count;
    this.next = data.next;
    this.previous = data.previous;
  }

  public get currentPage(): number {
    return (this.previous || 0) + 1;
  }
}

export type TAllNewsResponse = TMetaData & {
  results: INewsItem[];
};

export type TNewsFilesResponse = TMetaData & {
  results: IFile[];
};
