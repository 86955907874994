import type { IFile } from '@/Models/File';
import type { TMetaData } from '@/Models/News';

export interface AddedFileStatus {
  [key: string]: string;
}

export interface IBasket {
  images_count: number;
  docs_count: number;
  videos_count: number;
  count: number;
  total_size: number;
  total_size_human: number;
  added_files?: AddedFileStatus[];
}

export class BasketClass {
  imagesCount: number;
  docsCount: number;
  videosCount: number;
  count: number;
  totalSize: number;
  totalSizeHuman: number;

  constructor(data: IBasket) {
    this.imagesCount = data.images_count;
    this.docsCount = data.docs_count;
    this.videosCount = data.videos_count;
    this.count = data.count;
    this.totalSize = data.total_size;
    this.totalSizeHuman = data.total_size_human;
  }
}

export type TBasketPageData = TMetaData & {
  results: IFile[];
};

export interface IBasketArchive {
  archive_check_id: string;
}

export interface IBasketArchivingStatus {
  status: 'wait' | 'ready' | 'error';
  link?: string;
}

export class BasketArchivingStatusClass {
  status: 'wait' | 'ready' | 'error';
  link?: string;

  constructor(data: IBasketArchivingStatus) {
    this.status = data.status;
    this.link = data.link;
  }
}
