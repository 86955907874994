<script lang="ts">
export default {
  name: 'NewsItemsList',
};
</script>

<script setup lang="ts">
import type { NewsItemClass } from '@/Models/News';

import NewsItemComponent from '@/components/News/NewsItemComponent.vue';
import BaseLink from '@/components/ui/BaseLink.vue';

export interface INewsVerticalItemsListProps {
  newsItems: NewsItemClass[];
  title?: string;
}

const newsItemsListProps = withDefaults(defineProps<INewsVerticalItemsListProps>(), {
  title: 'Последние новости',
});
</script>

<template>
  <div class="news-vertical-items-list container">
    <slot name="title">
      <h2 class="page-h2-title h2">{{ newsItemsListProps.title }}</h2>
    </slot>
    <div class="news-items news-items__vertical">
      <NewsItemComponent
        :key="newsItem.id"
        v-for="newsItem in newsItemsListProps.newsItems"
        :newsItem="newsItem"
        class="news-items__item"
        :isHorizontal="false"
      ></NewsItemComponent>
    </div>
    <slot name="action-component">
      <BaseLink href="/news/" type="button">Все новости</BaseLink>
    </slot>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/scss/_variables.scss';
@import '@/assets/scss/mixins.scss';

.news-items.news-items__vertical {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, 370px));
  column-gap: 40px;

  @media (max-width: 1365px) {
    grid-template-columns: repeat(auto-fit, minmax(260px, calc(32.5% - 20px)));
  }

  @include screenEqAndLess1024 {
    column-gap: 30px;
    grid-template-columns: 1fr 1fr;
  }

  @include screenEqAndLess768 {
    grid-template-columns: 1fr;
  }

  .news-items__item {
    max-width: 370px;
    @include screenEqAndLess1024 {
      max-width: 100%;
    }
  }
}

.news-items {
  @include screenEqAndLess1024 {
    grid-template-columns: 1fr;
  }
}
</style>
