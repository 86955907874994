<script lang="ts">
export default {
  name: 'BaseMenu',
};
</script>

<script setup lang="ts">
import { ref } from 'vue';
import BaseLink from '@/components/ui/BaseLink.vue';
import type { IMenuItem } from '@/types/Iterfaces';

interface IBaseMenu {
  menuItems: IMenuItem[];
  fillColor?: string;
  isActiveLinkClass: boolean;
  root?: 'header' | 'footer';
}

const props = withDefaults(defineProps<IBaseMenu>(), {
  root: 'header',
});

const resetActiveLinks = ref(false);

const onToggleDropdown = (v: boolean) => {
  resetActiveLinks.value = v;
}
</script>

<template>
  <div class="menu" :style="{ color: props.fillColor }">
    <component
      :is="props.root === 'footer' ? BaseLink : component || BaseLink"
      :class="{ 'router-link-exact-active--red-bottom-border': props.isActiveLinkClass && !resetActiveLinks }"
      :key="href"
      v-for="{ href, footerHref, text, footerText, component } in props.menuItems"
      :href="props.root === 'footer' ? footerHref || href : href"
      :color="props.fillColor"
      @toggleDropdown="onToggleDropdown"
      >{{ props.root === 'footer' ? footerText || text : text }}
    </component>
  </div>
</template>

<style scoped lang="scss">
.menu {
  display: flex;
  gap: 40px;
}
</style>
