import { ref } from 'vue';
import { defineStore } from 'pinia';
import { API_URL, axiosInstance } from '@/api/axios-instance';
import { BasketClass, BasketArchivingStatusClass } from '@/Models/Basket';
import type { IBasket, IBasketArchive, TBasketPageData, IBasketArchivingStatus } from '@/Models/Basket';
import { FileClass } from '@/Models/File';
import type { TMetaData } from '@/Models/News';
import type { AxiosResponse } from 'axios';

export interface IBasketStore {
  basket: BasketClass | undefined;
  basketPage: FileClass[] | [];
  basketPageMeta: TMetaData;
  isNextBasketPageFetching: boolean;
  archiveCheckId: string | undefined;
  archivingStatus: BasketArchivingStatusClass | undefined;
  fetchBasket: () => Promise<void>;
  fetchBasketPage: (params: { page: number }) => Promise<void>;
  fetchNextBasketPage: () => Promise<void>;
  addToBasket: (id: number) => Promise<number | boolean>;
  removeFromBasket: (id: number) => Promise<number | boolean>;
  requestArchive: () => Promise<void>;
  purgeBasket: () => Promise<void>;
  fetchBasketArchivingStatus: (id: string) => Promise<void>;
  addBatchToBasket: (id: number[]) => Promise<AxiosResponse<IBasket>>;
}

export const useBasketStore = defineStore('basket', () => {
  const basket = ref<BasketClass | undefined>();
  const basketPage = ref<FileClass[] | []>([]);
  const basketPageMeta = ref<TMetaData>({
    count: 0,
    next: null,
    previous: null,
  });
  const isBasketPageLoading = ref<boolean>(false);
  const isNextBasketPageFetching = ref<boolean>(false);
  const archiveCheckId = ref<string | undefined>();
  const archivingStatus = ref<BasketArchivingStatusClass | undefined>();

  const fetchBasket = async () => {
    try {
      const { data } = await axiosInstance.get<IBasket>(API_URL.BASKET);
      basket.value = new BasketClass(data);
    } catch (error: any) {
      console.error(error);
    }
  };

  const addToBasket = async (id: number): Promise<number | false> => {
    try {
      const { data } = await axiosInstance.post<IBasket>(API_URL.BASKET, { id: [id] });
      if (!data.added_files?.find(el => el[`${id}`] === 'ok')) {
        throw new Error(`Unable to add files to basket (${data.added_files})`);
      }
      basket.value = new BasketClass(data);
      return id;
    } catch (error: any) {
      console.error(error);
      return false;
    }
  };

  const addBatchToBasket = async (id: number[]): Promise<AxiosResponse<IBasket>> => {
    return axiosInstance.post<IBasket>(API_URL.BASKET, { id });
  };

  const removeFromBasket = async (id: number): Promise<number | false> => {
    try {
      const { data } = await axiosInstance.delete<IBasket>(API_URL.BASKET, { data: { id: [id] } });
      basket.value = new BasketClass(data);
      return id;
    } catch (error: any) {
      console.error(error);
      return false;
    }
  };

  const requestArchive = async () => {
    try {
      const { data } = await axiosInstance.post<IBasketArchive>(API_URL.BASKET_ARCHIVE);
      archiveCheckId.value = data.archive_check_id;
    } catch (error: any) {
      console.error(error);
    }
  };

  const purgeBasket = async () => {
    try {
      const { data } = await axiosInstance.post<IBasket>(API_URL.BASKET_PURGE);
      basket.value = new BasketClass(data);
      basketPage.value = [];
      basketPageMeta.value = {
        count: 0,
        next: null,
        previous: null,
      };
      isBasketPageLoading.value = false;
      isNextBasketPageFetching.value = false;
      archiveCheckId.value = undefined;
      archivingStatus.value = undefined;
    } catch (error: any) {
      console.error(error);
    }
  };

  const fetchBasketPage = async (params = { page: 1 }) => {
    try {
      isBasketPageLoading.value = true;
      const newsPagesRequests = [];
      for (let i = 1; i <= params.page; i++) {
        newsPagesRequests.push(axiosInstance.get<TBasketPageData>(API_URL.BASKET_LIST, { params: { page: i } }));
      }
      const data = (await Promise.all(newsPagesRequests)).map(({ data }) => data);
      basketPage.value = data.reduce((acc: FileClass[], currentValue) => {
        acc = [...acc, ...currentValue.results.map((file) => new FileClass(file))];
        return acc;
      }, []);
      basketPageMeta.value = {
        count: data[data.length - 1].count,
        next: data[data.length - 1].next,
        previous: data[data.length - 1].previous,
      };
    } catch (error) {
      console.error(error);
    } finally {
      isBasketPageLoading.value = false;
    }
  };

  const fetchNextBasketPage = async () => {
    if (!basketPageMeta.value.next) {
      return;
    }

    try {
      isNextBasketPageFetching.value = true;
      const { data } = await axiosInstance.get<TBasketPageData>(API_URL.BASKET_LIST, {
        params: { page: basketPageMeta.value.next },
      });
      basketPage.value = [...basketPage.value, ...data.results.map((newsItem) => new FileClass(newsItem))];
      basketPageMeta.value = {
        count: data.count,
        next: data.next,
        previous: data.previous,
      };
    } catch (error) {
      console.error(error);
    } finally {
      isNextBasketPageFetching.value = false;
    }
  };

  const fetchBasketArchivingStatus = async (id: string) => {
    try {
      const { data } = await axiosInstance.get<IBasketArchivingStatus>(`${API_URL.BASKET_ARCHIVE}${id}`);
      archivingStatus.value = new BasketArchivingStatusClass(data);
    } catch (error: any) {
      archivingStatus.value = new BasketArchivingStatusClass({ status: 'error' });
      console.error(error);
    }
  };

  return {
    basket,
    basketPage,
    basketPageMeta,
    isNextBasketPageFetching,
    archiveCheckId,
    archivingStatus,
    fetchBasket,
    fetchBasketPage,
    fetchNextBasketPage,
    addToBasket,
    removeFromBasket,
    requestArchive,
    purgeBasket,
    fetchBasketArchivingStatus,
    addBatchToBasket,
  };
});
