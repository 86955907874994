import { ref } from 'vue';
import { defineStore } from 'pinia';
import { API_URL, axiosInstance } from '@/api/axios-instance';
import { ModelSpecificationsClass, ModelPageClass, ModelsMenuClass } from '@/Models/Models';
import type { IModelSpecification, IModelsMenu } from '@/Models/Models';

export const useModelStore = defineStore('model', () => {
  const modelSpecs = ref<ModelSpecificationsClass | undefined>();
  const modelData = ref<ModelPageClass | undefined>();
  const modelsMenu = ref<ModelsMenuClass[] | undefined>();

  const fetchModelSpecsData = async (slug: string) => {
    try {
      const { data } = await axiosInstance.get<IModelSpecification[]>(`${API_URL.MODELS}${slug}/specs/`);
      modelSpecs.value = new ModelSpecificationsClass(data);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const fetchModelsPageData = async (slug: string) => {
    try {
      const { data } = await axiosInstance.get<any>(`${API_URL.MODELS_PAGE}${slug}`);
      modelData.value = new ModelPageClass(data);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const fetchModelsMenu = async () => {
    try {
      const { data } = await axiosInstance.get<IModelsMenu[]>(API_URL.MODELS_MENU);
      modelsMenu.value = data.map(item => new ModelsMenuClass(item));
    } catch (error) {
      console.error(error);
    }
  };

  return {
    modelSpecs,
    modelData,
    modelsMenu,
    fetchModelSpecsData,
    fetchModelsPageData,
    fetchModelsMenu,
  };
});
