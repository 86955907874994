type TAppeal = 'he' | 'she' | null;

export interface IUserModel {
  id?: number;
  read_only?: boolean;
  last_login?: string;
  email: string;
  is_active: boolean;
  password: string | undefined;
  password1?: string | undefined;
  password2?: string | undefined;
  password_confirm?: string | undefined;
  appeal: TAppeal;
  position?: string;
  company?: string;
  country?: string;
  city?: string;
  phone?: string;
  site?: string;
  user_role?: string;
  first_name?: string;
  last_name?: string;
  is_subscribed: boolean;
  pd_agreement: boolean;
}

export interface IUser {
  id?: number;
  readOnly?: boolean;
  lastLogin?: string;
  email?: string;
  isActive?: boolean;
  password?: string;
  password1?: string;
  password2?: string;
  passwordConfirm?: string;
  appeal: TAppeal;
  position?: string;
  company?: string;
  country?: string;
  city?: string;
  phone?: string;
  site?: string;
  userRole?: string;
  firstName?: string;
  lastName?: string;
  isSubscribed?: boolean;
  pdAgreement?: boolean;
}

export class User implements IUser {
  id: number | undefined = undefined;
  readOnly: boolean | undefined = false;
  lastLogin: string | undefined = undefined;
  email: string | undefined = undefined;
  isActive: boolean | undefined = undefined;
  password: string | undefined = undefined;
  passwordConfirm: string | undefined = undefined;
  password1: string | undefined = undefined;
  password2: string | undefined = undefined;
  appeal: TAppeal = null;
  position: string | undefined = undefined;
  company: string | undefined = undefined;
  country: string | undefined = undefined;
  city: string | undefined = undefined;
  phone: string | undefined = undefined;
  site: string | undefined = undefined;
  userRole: string | undefined = undefined;
  firstName: string | undefined = undefined;
  lastName: string | undefined = undefined;
  isSubscribed: boolean | undefined = undefined;
  pdAgreement: boolean | undefined = undefined;

  constructor(userData?: Partial<IUserModel>) {
    this.id = userData?.id;
    this.readOnly = userData?.read_only || undefined;
    this.lastLogin = userData?.last_login;
    this.email = userData?.email;
    this.isActive = userData?.is_active || undefined;
    this.password = userData?.password;
    this.passwordConfirm = userData?.password_confirm || undefined;
    this.appeal = userData?.appeal || null;
    this.position = userData?.position || undefined;
    this.company = userData?.company || undefined;
    this.country = userData?.country || undefined;
    this.city = userData?.city || undefined;
    this.phone = userData?.phone || undefined;
    this.site = userData?.site || undefined;
    this.userRole = userData?.user_role || undefined;
    this.firstName = userData?.first_name || undefined;
    this.lastName = userData?.last_name || undefined;
    this.isSubscribed = userData?.is_subscribed || undefined;
    this.pdAgreement = userData?.pd_agreement || undefined;
  }
}

type TUserModel = Omit<IUserModel, 'is_active'>;

export class UserModel implements TUserModel {
  email = '';
  appeal: TAppeal = null;
  company = '';
  position = '';
  first_name = '';
  last_name = '';
  password: string | undefined = undefined;
  password1: string | undefined = undefined;
  password2: string | undefined = undefined;
  country = '';
  city = '';
  phone = '';
  site = '';
  pd_agreement = false;
  is_subscribed = false;

  constructor(data: Partial<IUser>) {
    this.email = data.email || '';
    this.appeal = data.appeal || null;
    this.company = data.company || '';
    this.position = data.position || '';
    this.first_name = data.firstName || '';
    this.last_name = data.lastName || '';
    this.password = data.password || undefined;
    this.password1 = data.password1 || undefined;
    this.password2 = data.password2 || undefined;
    this.country = data.country || '';
    this.city = data.city || '';
    this.phone = data.phone || '';
    this.site = data.site || '';
    this.pd_agreement = data.pdAgreement || false;
    this.is_subscribed = data.isSubscribed || false;
  }
}
