<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="20" fill="#485054" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.51239 18.8488C15.418 16.2758 19.3559 14.5796 21.3263 13.76C26.9521 11.42 28.1211 11.0136 28.883 11.0001C29.0506 10.9972 29.4253 11.0387 29.668 11.2357C29.873 11.402 29.9294 11.6266 29.9563 11.7843C29.9833 11.9419 30.0169 12.3011 29.9902 12.5818C29.6854 15.785 28.3662 23.5584 27.6951 27.1461C27.4111 28.6642 26.852 29.1732 26.3107 29.223C25.1343 29.3312 24.2409 28.4455 23.1015 27.6986C21.3186 26.5299 20.3113 25.8023 18.5807 24.6619C16.5806 23.3438 17.8772 22.6194 19.017 21.4355C19.3153 21.1257 24.4986 16.4111 24.5989 15.9834C24.6115 15.9299 24.6231 15.7306 24.5047 15.6253C24.3862 15.52 24.2114 15.556 24.0852 15.5846C23.9064 15.6252 21.0581 17.5078 15.5404 21.2325C14.7319 21.7876 13.9996 22.0581 13.3435 22.0439C12.6202 22.0283 11.2288 21.635 10.1945 21.2988C8.92583 20.8864 7.91753 20.6683 8.00533 19.968C8.05106 19.6032 8.55341 19.2301 9.51239 18.8488Z"
      fill="white"
    />
  </svg>
</template>
