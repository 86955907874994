import { defineStore } from 'pinia';

export enum MATCH_MEDIAS_STRING {
  maxWidth768 = '(max-width: 768px)',
  minWidth768maxWidth1024 = '(min-width: 768px) and (max-width: 1024px)',
  minWidth1025 = '(min-width: 1025px)',
}

export enum MEDIA_QUERIES {
  maxWidth768 = 'maxWidth768', //  меньше или равно 768
  maxWidth1024 = 'maxWidth1024', //  меньше или равно 1024
  minWidth1025 = 'minWidth1025', //больше или равно 1025
}

export const useDeviceStore = defineStore('device', {
  state: () => ({
    mediaQuery: '',
  }),
  actions: {
    changeMediaQueryValue(newValue: string) {
      this.mediaQuery = newValue;
    },
  },
  getters: {
    isScreenLessOrEq1024: (state) =>
      state.mediaQuery === MEDIA_QUERIES.maxWidth1024 || state.mediaQuery === MEDIA_QUERIES.maxWidth768,
    isScreenLessOrEq768: (state) => state.mediaQuery === MEDIA_QUERIES.maxWidth768,
  },
});
