<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="20" fill="#485054" />
    <path
      d="M22.1055 24.9385C24.3878 24.4735 25.7555 23.3927 25.8278 23.3347C26.4957 22.7991 26.603 21.8235 26.0674 21.1556C25.5319 20.4877 24.5563 20.3804 23.8883 20.9159C23.8742 20.9274 22.4155 22.0464 20.0153 22.048C17.6153 22.0464 16.1257 20.9274 16.1116 20.9159C15.4436 20.3804 14.4681 20.4877 13.9325 21.1556C13.3969 21.8235 13.5042 22.7991 14.1721 23.3347C14.2454 23.3935 15.6696 24.5025 18.0159 24.9565L14.7459 28.3739C14.1516 28.9902 14.1695 29.9715 14.7858 30.5658C15.0866 30.8558 15.4743 31 15.8616 31C16.2677 31 16.6735 30.8413 16.9776 30.5258L20.0154 27.2898L23.3601 30.5468C23.9661 31.1518 24.9474 31.1509 25.5523 30.5451C26.1571 29.9392 26.1564 28.9578 25.5505 28.3529L22.1055 24.9385Z"
      fill="white"
    />
    <path
      d="M20.0928 9C16.9442 9 14.3918 11.5525 14.3918 14.701C14.3918 17.8496 16.9442 20.4022 20.0928 20.4022C23.2414 20.4022 25.7937 17.8496 25.7937 14.701C25.7937 11.5525 23.2414 9 20.0928 9ZM20.0928 17.0578C18.7912 17.0578 17.7361 16.0026 17.7361 14.7011C17.7361 13.3995 18.7912 12.3444 20.0928 12.3444C21.3943 12.3444 22.4494 13.3995 22.4494 14.7011C22.4494 16.0026 21.3943 17.0578 20.0928 17.0578Z"
      fill="white"
    />
  </svg>
</template>
