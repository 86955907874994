import axios from 'axios';

export const API_BASE = '/api';

export const API_URL = {
  REGISTER: '/account/register/',
  UPDATE_USER: '/account/update/',
  LOGIN: '/account/login/',
  ACCOUNT: '/account/',
  RESET_PASSWORD: '/account/password_reset/',
  CONTACTS: '/page/contacts/',
  FEEDBACK: '/contacts/',
  MAIN_PAGE: '/page/',
  NEWS: '/news/',
  MODELS: '/models/',
  MODELS_MENU: '/models/menu/',
  MODELS_PAGE: '/page/models/',
  AVAILABLE_TAGS: '/news/availabletags/',
  AVAILABLE_DATES: '/news/availabledates/',
  BASKET: '/basket/',
  BASKET_ARCHIVE: '/basket/archive/',
  BASKET_LIST: '/basket/list/',
  BASKET_NEWS: '/basket/news/',
  BASKET_PURGE: '/basket/purge/',
  FILES: '/files/',
  FILES_AVAILABLE_TAGS: '/files/availabletags/',
};

export default {
  API_BASE,
  API_URL,
};

export const axiosInstance = axios.create({
  baseURL: API_BASE,
  withCredentials: true,
});

axiosInstance.interceptors.request.use((config) => {
  // Добавляем к запросу заголовок X-CSRFToken
  const [, csrftoken] = /csrftoken=([^;]+);?/.exec(document.cookie) || [];
  if (csrftoken && config.headers) {
    config.headers['X-CSRFToken'] = csrftoken;
  }
  return config;
});
