<script lang="ts">
export default {
  name: 'TheFooter',
};
</script>

<script setup lang="ts">
import { computed } from 'vue';
import type { DefineComponent } from 'vue';
import LogoComponent from '@/components/LogoComponent.vue';
import BaseMenu from '@/components/BaseMenu.vue';
import { menuItems } from '@/utils/config';
import { useUserAccount } from '@/stores/userAccount';
import { footerData } from '@/utils/config';
import BaseLink from '@/components/ui/BaseLink.vue';
import VkIcon from '@/components/icons/vk-icon.vue';
import OkIcon from '@/components/icons/ok-icon.vue';
import TelegramIcon from '@/components/icons/telegram-icon.vue';
import YoutubeIcon from '@/components/icons/youtube-icon.vue';
import DzenIcon from '@/components/icons/dzen-icon.vue';

type IKeyInterface = Record<string, DefineComponent>;

const socialIcons: IKeyInterface = {
  vk: VkIcon,
  ok: OkIcon,
  telegram: TelegramIcon,
  youtube: YoutubeIcon,
  dzen: DzenIcon,
};

const userAccountStore = useUserAccount();

const socialLinks = computed<{ name: string; link: string }[]>(() =>
  footerData.socialLinks.filter((socialLink) => !!socialLink.link),
);
</script>

<template>
  <div class="gray-background">
    <footer class="footer container">
      <div class="footer-menu footer-content__line">
        <LogoComponent :isLight="true" />
        <BaseMenu
          :menuItems="menuItems"
          fillColor="white"
          :isActiveLinkClass="false"
          :isShowOnMobile="true"
          root="footer"
        />
      </div>
      <div class="footer-social-links__wrapper footer-content__line">
        <div class="footer-social-links__left-side-block">
          <span class="footer-social-links__company-text">{{ footerData.year }} {{ footerData.companyName }}</span>
          <BaseLink :href="footerData.legalInformation.link" target="_blank" color="white"
            >{{ footerData.legalInformation.text }}
          </BaseLink>
        </div>
        <div class="footer-social-links__right-side-block">
          <BaseLink :key="socialLink.link" v-for="socialLink in socialLinks" :href="socialLink.link">
            <component v-bind:is="socialIcons[`${socialLink.name}`]"></component>
          </BaseLink>
        </div>
      </div>
      <div class="footer-notes__wrapper footer-content__line" v-if="footerData.notes" v-html="footerData.notes"></div>
    </footer>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/scss/_variables.scss';
@import '@/assets/scss/mixins.scss';

.footer.container {
  padding-top: 45px;
}

.footer-menu {
  display: flex;
  justify-content: space-between;
  min-height: 24px;

  .logo {
    min-height: 24px;
  }

  @include screenEqAndLess1024 {
    flex-direction: column;
    row-gap: 45px;
  }

  .menu {
    @include screenEqAndLess768 {
      display: grid;
      grid-template-columns: repeat(auto-fill, calc(30% - 16px));
      column-gap: 16px;
    }

    @media (max-width: 500px) {
      grid-template-columns: repeat(auto-fill, calc(50% - 16px));
    }
  }
}

.footer-content__line {
  padding: 24px 0;
  border-bottom: 1px solid rgba(166, 183, 190, 0.3);

  &:first-child {
    padding-top: 0;
    padding-bottom: 22px;
  }

  &:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }
}

.footer-notes__wrapper {
  color: $colorDense;
  font-size: 12px;
  line-height: 18px;
}

.footer-social-links__wrapper {
  display: flex;
  justify-content: space-between;

  @include screenEqAndLess768 {
    flex-direction: column;
    gap: 24px;
  }
}

.footer-social-links__left-side-block {
  display: flex;
  align-items: center;
  color: $colorWhite;
  gap: 40px;
  font-size: 12px;
  line-height: 18px;

  @include screenEqAndLess768 {
    flex-direction: column;
    gap: 24px;
    align-items: flex-start;
  }
}

.footer-social-links__right-side-block {
  display: flex;
  gap: 16px;
}

.footer-social-links__company-text {
  color: $colorLightBrand;
}
</style>
