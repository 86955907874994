import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
import { User, UserModel } from '@/Models/User';
import type { IUserModel } from '@/Models/User';
import { API_URL, axiosInstance } from '@/api/axios-instance';
import type { validateOptions } from 'ant-design-vue/es/form/useForm';
import type { RuleError } from 'ant-design-vue/es/form/interface';
import { createBackendValidator } from '@/constants/formContstants';
import { isUndefined, omitBy } from 'lodash-es';

export type TValidateFieldFunc = (
  name: string,
  value: any,
  rules: Record<string, unknown>[],
  option?: validateOptions,
) => Promise<RuleError[]>;

export const useUserAccount = defineStore('userAccount', () => {
  const user = ref<User>(new User());
  const isUserAccountLoading = ref<boolean>(true);
  const isFetchingUserData = ref<boolean>(false);

  const isUserActive = computed<boolean>(() => !!user.value.isActive);

  const isUserCreated = computed<boolean>(() => !!user.value.id);

  const getIsUserAccountLoading = computed(() => {
    return isUserAccountLoading.value;
  });

  const getIsFetchingUserData = computed<boolean>(() => isFetchingUserData.value);

  const setIsActive = (value: boolean) => {
    user.value.isActive = value;
  };

  const setUser = (value: IUserModel) => {
    user.value = { ...new User(value), pdAgreement: true };
  };

  const updateUser = (payload: Partial<User>) => {
    user.value = { ...user.value, ...omitBy(payload, isUndefined) };
  };

  const fetchUserAccount = async () => {
    try {
      isUserAccountLoading.value = true;
      const { data } = await axiosInstance.get<IUserModel>(API_URL.ACCOUNT);
      setUser(data);
    } catch (error: any) {
      if (error?.response?.status !== 403) {
        console.error(error);
      }
    } finally {
      isUserAccountLoading.value = false;
    }
  };

  const fetchLoginUser = async (
    { email, password }: { email: string; password: string },
    filedValidator: TValidateFieldFunc,
  ) => {
    try {
      isFetchingUserData.value = true;
      const { data } = await axiosInstance.post<IUserModel>(API_URL.LOGIN, {
        email: email,
        password: password,
      });
      setUser(data);
    } catch (error: any) {
      if (error?.response?.status === 400 && error?.response?.data) {
        const { data } = error.response;
        Object.keys(data).forEach((key: string) => {
          const rule = { validator: createBackendValidator(data[key][0]) };
          if (key === 'errors' || key === 'details') {
            key = 'password';
            filedValidator('email', '', [{ validator: createBackendValidator('') }]);
          }
          filedValidator(key, '', [rule]);
        });
      }
      throw error;
    } finally {
      isFetchingUserData.value = false;
    }
  };

  const fetchRestorePassword = async ({ email }: { email: string }, filedValidator: TValidateFieldFunc) => {
    try {
      isFetchingUserData.value = true;
      await axiosInstance.post<{ email: string }>(API_URL.RESET_PASSWORD, {
        email: email,
      });
    } catch (error: any) {
      if (error?.response?.status === 400 && error?.response?.data) {
        const { data } = error.response;
        Object.keys(data).forEach((key: string) => {
          const rule = { validator: createBackendValidator(data[key][0]) };
          filedValidator('email', '', [rule]);
        });
      }
      throw error;
    } finally {
      isFetchingUserData.value = false;
    }
  };

  const fetchCreateUser = async (payload: UserModel, filedValidator: TValidateFieldFunc) => {
    try {
      isFetchingUserData.value = true;
      const { data } = await axiosInstance.post<IUserModel>(API_URL.REGISTER, payload);
      setUser(data);
    } catch (error: any) {
      if (error?.response?.status === 400 && error?.response?.data) {
        const { data } = error.response;
        Object.keys(data).forEach((key: string) => {
          const rule = { validator: createBackendValidator(data[key][0]) };
          filedValidator(key, '', [rule]);
        }, {});
      }
      throw error;
    } finally {
      isFetchingUserData.value = false;
    }
  };

  const fetchEditUser = async (payload: UserModel, filedValidator: TValidateFieldFunc) => {
    try {
      isFetchingUserData.value = true;
      const { data } = await axiosInstance.put<IUserModel>(API_URL.UPDATE_USER, payload);
      updateUser(new User(data));
    } catch (error: any) {
      if (error?.response?.status === 400 && error?.response?.data) {
        const { data } = error.response;
        Object.keys(data).forEach((key: string) => {
          const rule = { validator: createBackendValidator(data[key][0]) };
          filedValidator(key, '', [rule]);
        }, {});
      }
      throw error;
    } finally {
      isFetchingUserData.value = false;
    }
  };

  return {
    user,
    isUserActive,
    setIsActive,
    setUser,
    fetchUserAccount,
    fetchLoginUser,
    fetchRestorePassword,
    getIsUserAccountLoading,
    fetchCreateUser,
    fetchEditUser,
    isUserCreated,
    getIsFetchingUserData,
  };
});
