import { useUserAccount } from '@/stores/userAccount';

export const authorised = async () => {
  const userAccountStore = useUserAccount();

  if (!userAccountStore.isUserActive) {
    return { name: 'home' };
  }
  return true;
};

export const fetchUserAccount = async () => {
  const userAccountStore = useUserAccount();
  await userAccountStore.fetchUserAccount();
}
