import { MATCH_MEDIAS_STRING, MEDIA_QUERIES, useDeviceStore } from '@/stores/device';
import { onMounted, onUnmounted } from 'vue';

export const useSetMediaQuery = () => {
  const deviceStore = useDeviceStore();
  const mobileDevicesMediaQueryList = window.matchMedia(MATCH_MEDIAS_STRING.maxWidth768);
  const tableDevicesMediaQueryList = window.matchMedia(MATCH_MEDIAS_STRING.minWidth768maxWidth1024);
  const desktopDevicesMediaQueryList = window.matchMedia(MATCH_MEDIAS_STRING.minWidth1025);

  function handleTabletDeviceChange(e: MediaQueryListEvent | MediaQueryList) {
    if (e.matches) {
      switch (e.media) {
        case MATCH_MEDIAS_STRING.maxWidth768:
          deviceStore.changeMediaQueryValue(MEDIA_QUERIES.maxWidth768);
          break;
        case MATCH_MEDIAS_STRING.minWidth768maxWidth1024:
          deviceStore.changeMediaQueryValue(MEDIA_QUERIES.maxWidth1024);
          break;
        case MATCH_MEDIAS_STRING.minWidth1025:
          deviceStore.changeMediaQueryValue(MEDIA_QUERIES.minWidth1025);
          break;
        default:
          break;
      }
    }
  }

  onMounted(() => {
    tableDevicesMediaQueryList.addEventListener('change', handleTabletDeviceChange);
    mobileDevicesMediaQueryList.addEventListener('change', handleTabletDeviceChange);
    desktopDevicesMediaQueryList.addEventListener('change', handleTabletDeviceChange);
    handleTabletDeviceChange(tableDevicesMediaQueryList);
    handleTabletDeviceChange(mobileDevicesMediaQueryList);
    handleTabletDeviceChange(desktopDevicesMediaQueryList);
  });

  onUnmounted(() => {
    tableDevicesMediaQueryList.removeEventListener('change', handleTabletDeviceChange);
    mobileDevicesMediaQueryList.removeEventListener('change', handleTabletDeviceChange);
    desktopDevicesMediaQueryList.removeEventListener('change', handleTabletDeviceChange);
  });
};

export const useSetDeviceHeight = (): void => {
  function calculateVh() {
    const vh = window.innerHeight * 0.01;
    if (!vh) {
      return;
    }
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  function setVh() {
    calculateVh();
    window.addEventListener('resize', calculateVh);
  }

  onMounted(() => {
    setVh();
  });

  onUnmounted(() => {
    window.removeEventListener('resize', calculateVh);
  });
};

export const getFunctionsToFixBodyForModal = () => {
  const fixBody = () => {
    const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
    const body = document.body;
    body.style.position = 'fixed';
    body.style.top = `-${scrollY}`;
  };
  const unfixBody = () => {
    const body = document.body;
    const scrollY = body.style.top;
    body.style.position = '';
    body.style.top = '';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
  };

  const calculateScrollY = () => document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);

  const addCalculateScroll = () => {
    onMounted(() => {
      document.documentElement.style.setProperty('--scroll-y', '0px');
      window.addEventListener('scroll', calculateScrollY);
    });

    onUnmounted(() => {
      window.removeEventListener('scroll', calculateScrollY);
    });
  };

  return { fixBody, unfixBody, addCalculateScroll };
};
