<script lang="ts">
export default {
  name: 'NewsItemComponent',
};
</script>

<script setup lang="ts">
import BaseImage from '@/components/ui/BaseImage.vue';
import { computed } from 'vue';
import BaseLink from '@/components/ui/BaseLink.vue';
import RightArrowLinkIcon from '@/components/icons/RightArrowLinkIcon.vue';
import type { NewsItemClass } from '@/Models/News';
import type { IImageSize } from '@/types/Iterfaces';
import { getFormattedDate } from '@/utils/getFormattedDate';

interface INewsItemComponentProps {
  newsItem: NewsItemClass;
  isHorizontal: boolean;
  imageSizes?: IImageSize[];
  imageWidth?: number;
  imageHeight?: number;
}

const newsItemProps = withDefaults(defineProps<INewsItemComponentProps>(), {
  isHorizontal: false,
  imageSizes: () => [
    {
      imageWidth: 390,
      screenWidth: 420,
    },
    {
      imageWidth: 736,
      screenWidth: 768,
    },
    {
      imageWidth: 480,
      screenWidth: 1024,
    },
    {
      imageWidth: 370,
      screenWidth: 1350,
    },
  ],
  imageWidth: 370,
  imageHeight: 247,
});

const srcFunction = computed(() => {
  return (width: number) => `${newsItemProps.newsItem.pathToPreview}?w=${width}`;
});

const imageAlt = computed(() => {
  return `Картинка новости - ${newsItemProps.newsItem.title}`;
});

const formattedDate = computed(() => {
  return getFormattedDate(newsItemProps.newsItem.pubDate);
});
</script>

<template>
  <div class="news-item" :class="{ 'news-item__horizontal': newsItemProps.isHorizontal }">
    <a :href="newsItemProps.newsItem.linkToNewsItemPage">
      <BaseImage
        class="news-item__image"
        :srcFunc="srcFunction"
        :width="newsItemProps.imageWidth"
        :height="newsItemProps.imageHeight"
        :sizes="newsItemProps.imageSizes"
        :alt="imageAlt"
      ></BaseImage>    
    </a>
    <div class="news-item__content">
      <slot name="title">
        <a :href="newsItemProps.newsItem.linkToNewsItemPage">
          <h5 class="h5 news-item__title">
            {{ newsItemProps.newsItem.title }}
          </h5>
        </a>
      </slot>
      <p class="news-item__date">{{ formattedDate }}</p>
      <div class="news-item__brief" v-html="newsItemProps.newsItem.briefHtml"></div>
      <div class="news-item__action-wrapper">
        <slot name="action-component">
          <BaseLink class="news-item__link" :href="newsItemProps.newsItem.linkToNewsItemPage"
            >Читать далее
            <template v-slot:right-icon>
              <RightArrowLinkIcon />
            </template>
          </BaseLink>
        </slot>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/scss/_variables.scss';
@import '@/assets/scss/mixins.scss';

.news-item__title {
  line-height: 1.5;
  text-align: left;
}

.news-item__image {
  flex-shrink: 0;
}

.news-item {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.news-item__horizontal {
  gap: 40px;
  display: grid;
  grid-template-columns: 370px 1fr;

  @include screenEqAndLess1024 {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}

.news-item__date {
  color: $colorLightBrand;
  margin-bottom: 12px;
}

.news-item__link {
  column-gap: 10px;
  height: fit-content;
}

.news-item__action-wrapper {
  margin-top: 12px;
}

.news-item__brief {
  :global(p) {
    margin-bottom: 0;
  }
}

.news-item__content {
  display: grid;
  grid-template-rows: max-content 33px 1fr 33px;
  height: 100%;
  gap: 12px;
}
</style>
