<script setup lang="ts">
import { LoadingOutlined } from '@ant-design/icons-vue';

import { computed } from 'vue';

interface IBaseButtonProps {
  type?: 'button' | 'submit' | 'text';
  text?: string;
  basic?: boolean;
  disabled?: boolean;
  loading?: boolean;
  center?: boolean;
}

const props = withDefaults(defineProps<IBaseButtonProps>(), {
  type: 'button',
  text: '',
  basic: false,
  disabled: false,
  loading: false,
  center: false,
});

const buttonClass = computed(() => ({
  ['button--border']: props.basic,
  ['button--loading']: props.loading,
  ['button--center']: props.center,
}));
</script>

<template>
  <a-button class="button" :class="buttonClass" :type="props.type" :disabled="props.disabled">
    <slot name="left-icon"></slot>
    <slot></slot>
    <slot name="right-icon"></slot>
    <slot name="loading-icon" v-if="props.loading">
      <LoadingOutlined class="loading-icon" />
    </slot>
  </a-button>
</template>

<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';
@import '@/assets/scss/mixins.scss';

.ant-btn-text:hover,
.ant-btn-text:focus {
  color: $colorDarkBrand;
  background-color: initial;
}

.ant-btn-text {
  color: $colorDarkBrand;
  @include hover;
}

.button {
  display: flex;
  align-items: center;
  background-color: transparent;
  gap: 16px;
  color: $colorDarkBrand;
}

.button--full-width {
  width: 100%;
}

.button--padding {
  padding: $buttonVerticalPaddings $buttonHorizontalPaddings;
  line-height: 24px;
}

.button--border {
  @extend .button--padding;
  border: 1px $buttonBorder solid;

  @include borderedButtonHover;

  &[disabled] {
    border-color: $colorLightBrand;
  }
}

.button--loading {
  padding-right: 67px;

  .loading-icon {
    position: absolute;
    right: 31px;
  }
}

.button--center {
  margin: auto;
}
</style>
