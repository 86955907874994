<script lang="ts">
export default {
  name: 'ModelsDropdown',
};
</script>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/scss';
import 'swiper/scss/navigation';
import { useModelStore } from '@/stores/models';
import BaseButton from '@/components/ui/BaseButton.vue';
import BaseImage from '@/components/ui/BaseImage.vue';
import SwiperRightIcon from '@/components/MediaComponents/SwiperRightIcon.vue';
import SwiperLeftIcon from '@/components/MediaComponents/SwiperLeftIcon.vue';

interface IModelsDropdown {
  color?: string;
}

const props = defineProps<IModelsDropdown>();

const emit = defineEmits(['toggleDropdown']);

const route = useRoute();

const modelStore = useModelStore();

const showDropdownContent = ref<boolean>(false);

const maxWidth = 228;
const maxHeight = 129;
const imageSizes = [
  {
    imageWidth: 228,
    screenWidth: 1350,
  },
];
const srcFunction = computed(() => {
  return (preview: string) =>
    (width = 370) =>
      `${preview}?w=${width}`;
});

const toggleDropdown = () => {
  showDropdownContent.value = !showDropdownContent.value;
  emit('toggleDropdown', showDropdownContent.value);
};

watch(
  () => route.path,
  async newPath => {
    showDropdownContent.value = false;
  }
);
</script>

<template>
  <BaseButton
    class="dropdown-button"
    :class="{ 'dropdown-active': showDropdownContent }"
    :color="props.color"
    @click="toggleDropdown"
    type="text"
    v-bind="$attrs"
    :href="null"
  >
    <slot></slot>
  </BaseButton>
  <div v-show="showDropdownContent" class="dropdown-backdrop" @click="toggleDropdown"></div>
  <div v-show="showDropdownContent" class="dropdown-content">
    <div class="container">
      <div class="media-image-swiper__wrapper">
        <swiper
          :modules="[Navigation]"
          :slides-per-view="4"
          :space-between="40"
          :navigation="{
            disabledClass: 'swiper__navigation-item--disabled',
            prevEl: '.navigation-item__prev-arrow',
            nextEl: '.navigation-item__next-arrow',
          }"
        >
          <swiper-slide v-for="{ about, href, preview, title } in modelStore.modelsMenu" :key="href">
            <a :href="href">
              <BaseImage
                :srcFunc="srcFunction(preview)"
                :width="maxWidth"
                :height="maxHeight"
                :alt="title"
                :sizes="imageSizes"
              />
              {{ title }}
              <span class="dropdown-content__about">{{ about }}</span>
            </a>
          </swiper-slide>
        </swiper>
        <SwiperLeftIcon class="navigation-item__prev-arrow swiper__navigation-item" />
        <SwiperRightIcon class="navigation-item__next-arrow swiper__navigation-item" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '@/assets/scss/mixins.scss';

.dropdown-content {
  position: absolute;
  background-color: $colorWhite;
  top: $headerHeight;
  left: 0;
  width: 100%;
  box-shadow: 0 0 10px rgba(48, 54, 58, 0.1);
  z-index: $zIndexHeaderDropdown;
  display: flex;
  justify-content: flex-start;

  :deep(.swiper-slide) {
    a {
      color: black;
      text-decoration: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-weight: 300;
      font-size: 18px;
      line-height: 150%;
      padding-top: 24px;
      padding-bottom: 36px;
    }

    &:hover {
      background-color: $colorBright;
    }
  }

  .container {
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.dropdown-content__about {
  color: $colorLightBrand;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  max-width: 150px;
  text-align: center;
}

.dropdown-button {
  display: flex;
  align-items: center;
  text-decoration: none;
  line-height: 1;
  padding: unset;
  margin: unset;
  border: unset;
  height: unset;

  &::after {
    animation: unset !important;
  }
}

.media-image-swiper__wrapper {
  position: relative;
  display: unset;
  margin: unset;
  position: unset;
  max-width: unset;
}

.swiper__navigation-item {
  position: absolute;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
  color: $colorDarkBrand;

  @include screenEqAndLess1024 {
    display: none;
  }

  &:hover {
    color: $colorRed;
  }
}

.navigation-item__next-arrow {
  right: -64px;
}

.navigation-item__prev-arrow {
  left: -64px;
}

.swiper__navigation-item--disabled {
  display: none;
}

.dropdown-active {
  color: $colorDense !important;
  position: relative;

  &:after {
    content: ' ';
    position: absolute;
    display: block;
    height: 2px;
    width: 100%;
    left: 0;
    background-color: red;
    bottom: 0;
    z-index: 10;
    right: 0;
  }
}

.dropdown-backdrop {
  position: absolute;
  top: $headerHeight;
  left: 0;
  width: 100%;
  height: calc(100vh + var(--scroll-y) - $headerHeight);
  overflow: hidden;
  z-index: $zIndexHeaderDropdownBackdrop;
}
</style>
