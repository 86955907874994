<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="20" fill="#485054" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M30.4952 14.352C30.6481 13.842 30.4952 13.4673 29.7673 13.4673H27.3605C26.7486 13.4673 26.4664 13.791 26.3134 14.148C26.3134 14.148 25.0895 17.1313 23.3556 19.0692C22.7946 19.6301 22.5396 19.8086 22.2336 19.8086C22.0807 19.8086 21.8592 19.6301 21.8592 19.1202V14.352C21.8592 13.74 21.6816 13.4673 21.1716 13.4673H17.3895C17.007 13.4673 16.777 13.7513 16.777 14.0205C16.777 14.6006 17.6439 14.7344 17.7333 16.3664V19.9106C17.7333 20.6877 17.5929 20.8286 17.287 20.8286C16.471 20.8286 14.4863 17.8319 13.3092 14.403C13.0785 13.7365 12.8471 13.4673 12.232 13.4673H9.8252C9.13754 13.4673 9 13.791 9 14.148C9 14.7854 9.81596 17.9473 12.7993 22.129C14.7881 24.9848 17.5903 26.5328 20.1401 26.5328C21.67 26.5328 21.8592 26.189 21.8592 25.5967V23.4384C21.8592 22.7507 22.0042 22.6135 22.4886 22.6135C22.8456 22.6135 23.4576 22.7919 24.8855 24.1689C26.5174 25.8007 26.7864 26.5328 27.7043 26.5328H30.1112C30.7988 26.5328 31.1426 26.189 30.9443 25.5105C30.7272 24.8342 29.9481 23.853 28.9143 22.6899C28.3533 22.027 27.5118 21.3131 27.2568 20.956C26.8999 20.4971 27.0019 20.2931 27.2568 19.8851C27.2568 19.8851 30.1892 15.7544 30.4952 14.352Z"
      fill="white"
    />
  </svg>
</template>
