import { NewsItemClass, TagClass } from './News';
import type { ITag, INewsItem } from './News';
import { FileClass } from '@/Models/File';
import type { IFile } from '@/Models/File';

export interface IModelSpec {
  id: number;
  title: string;
  value: string;
}

export interface IModelSpecification {
  id: number;
  name: string;
  specs: IModelSpec[];
}

export interface IModelPageData {
  attachments_limit: IFile[];
  body_html: string;
  brief_html: string;
  id: number;
  link: string;
  preview: string;
  presskit: string;
  pub_date: string;
  title: string;
  related_news: INewsItem[];
  specs: IModelSpecification[];
  tags: ITag[];
}

export class ModelSpecificationsClass {
  specifications: IModelSpecification[];

  constructor(specifications: IModelSpecification[]) {
    this.specifications = specifications;
  }
}

export class ModelPageClass {
  attachmentsLimit: FileClass[];
  bodyHtml: string;
  briefHtml: string;
  id: number;
  link: string;
  preview: string;
  presskit: string;
  pubDate: string;
  title: string;
  relatedNews: NewsItemClass[];
  specs: IModelSpecification[];
  tags: TagClass[];

  constructor(model: IModelPageData) {
    this.attachmentsLimit = model.attachments_limit.map((file) => new FileClass(file));
    this.bodyHtml = model.body_html;
    this.briefHtml = model.brief_html;
    this.id = model.id;
    this.link = model.link;
    this.preview = model.preview;
    this.presskit = model.presskit;
    this.pubDate = model.pub_date;
    this.title = model.title;
    this.relatedNews = model.related_news.map((news) => new NewsItemClass(news));
    this.specs = model.specs;
    this.tags = model.tags.map((tag) => new TagClass(tag));
  }
}

export interface IModelsMenu {
  about: string;
  link: string;
  preview: string;
  title: string;
}

export class ModelsMenuClass {
  about: string;
  href: string;
  preview: string;
  title: string;

  constructor(model: IModelsMenu) {
    this.about = model.about;
    this.href = model.link;
    this.preview = model.preview;
    this.title = model.title;
  }
}
