<script setup lang="ts">
import { ref } from 'vue';
import TheMainPage from '@/components/TheMainPage.vue';

const homeViewRef = ref(null);
</script>

<template>
  <main ref="homeViewRef" class="main home-view">
    <TheMainPage />
  </main>
</template>

<style scoped lang="scss">
@import '@/assets/scss/_variables.scss';
@import '@/assets/scss/mixins.scss';

.main {
  flex: 1 1 auto;
}

.home-view {
  position: relative;
  min-height: 620px;
}

.home-view__authorisation {
  height: 100%;
  overflow: hidden;
  position: absolute;
  width: 100%;
}

.home-view__authorisation-picture {
  width: 100%;
  display: block;
  position: absolute;
  height: 100%;

  @include screenEqAndLess1350 {
    height: 120%;
    width: 100%;
  }

  img {
    height: 100%;
    object-fit: cover;
    aspect-ratio: 1350/620;
    width: 100vw;
  }
}

.home-view__authorisation--wrapper {
  max-width: 1350px;
  width: 100%;
  position: absolute;
  height: 100%;
  transform: translateX(-50%);
  left: 50%;
}
</style>
