<script lang="ts" setup>
import { computed, ref, watchEffect } from 'vue';
import { RouterLink } from 'vue-router';

import BaseButton from '@/components/ui/BaseButton.vue';
import BaseLink from '@/components/ui/BaseLink.vue';
import BaseImage from '@/components/ui/BaseImage.vue';
import LogoComponent from '@/components/LogoComponent.vue';
import ArrowLeftIcon from '@/components/icons/ArrowLeftIcon.vue';
import ArrowRightIcon from '@/components/icons/ArrowRightIcon.vue';
import MenuProfileIcon from '@/components/icons/MenuProfileIcon.vue';
import PressCartIcon from '@/components/ui/PressCartIcon.vue';
import { useDeviceStore } from '@/stores/device';
import MobileMenuIcon from '@/components/icons/MobileMenuIcon.vue';
import BaseMenu from '@/components/BaseMenu.vue';

import { useUserAccount } from '@/stores/userAccount';
import { useBasketStore } from '@/stores/basket';
import { useModelStore } from '@/stores/models';
import { menuItems, unauthorisedMenuItems } from '@/utils/config';
import { getFunctionsToFixBodyForModal } from '@/utils/deviceUtils';

const isMobileMenuOpen = ref(false);
const mobileMenuPage = ref('index');

const userAccountStore: { getIsUserAccountLoading: boolean; isUserActive: boolean } = useUserAccount();
const basketStore = useBasketStore();
const modelStore = useModelStore();

const deviceStore = useDeviceStore();

const { fixBody, unfixBody } = getFunctionsToFixBodyForModal();

const mobileMenuClass = computed(() => {
  let mobileMenuClass = '';
  if (deviceStore.isScreenLessOrEq1024) {
    mobileMenuClass = isMobileMenuOpen.value ? 'container menu-mobile menu-mobile--open' : 'menu-mobile';
  }
  return `menu ${mobileMenuClass}`;
});

const getFixedClass = computed(() => {
  return isMobileMenuOpen.value ? 'position-fixed' : null;
});

const profileHref = computed(() => {
  return userAccountStore.isUserActive ? '/profile' : '/authorisation';
})

const onMobileMenuIconClick = () => {
  isMobileMenuOpen.value = !isMobileMenuOpen.value;
  if (isMobileMenuOpen.value) {
    fixBody();
  } else {
    unfixBody();
  }
};

const onMobileMenuItemClick = (href: string) => {
  if (href === '/models') {
    mobileMenuPage.value = 'models';
  } else {
    onMobileMenuIconClick();
  }
};

watchEffect(async () => {
  if (!userAccountStore.getIsUserAccountLoading) {
    await Promise.all([
      basketStore.fetchBasket(),
      modelStore.fetchModelsMenu(),
    ]);
  }
});

const maxWidth = 110;
const maxHeight = 70;
const imageSizes = [
  {
    imageWidth: 90,
    screenWidth: 1023,
  },
];
const srcFunction = computed(() => {
  return (preview: string) =>
    (width = 370) =>
      `${preview}?w=${width}`;
});
</script>

<template>
  <header :class="getFixedClass" class="header">
    <div class="container">
      <LogoComponent />
      <nav
        v-show="!userAccountStore.getIsUserAccountLoading"
        :class="{
          ['header-menu']: true,
        }"
      >
        <template v-if="!deviceStore.isScreenLessOrEq1024">
          <BaseMenu :isActiveLinkClass="true" :menuItems="menuItems" />
        </template>
        <div v-if="deviceStore.isScreenLessOrEq1024" class="menu-burger">
          <BaseButton type="text" @click="onMobileMenuIconClick">
            <MobileMenuIcon />
          </BaseButton>
        </div>
        <div class="header-menu__icons">
          <BaseLink
            v-if="!deviceStore.isScreenLessOrEq1024"
            :key="profileHref"
            class="base-link--full-height"
            :href="profileHref"
          >
            <MenuProfileIcon />
          </BaseLink>
          <BaseLink href="/basket">
            <PressCartIcon />
          </BaseLink>
        </div>
      </nav>
    </div>
    <div
      class="menu-mobile"
      v-show="mobileMenuPage === 'index'"
      :class="[mobileMenuClass]"
      @click="onMobileMenuIconClick"
    >
      <BaseLink
        v-for="{ href, text } in menuItems"
        :key="href"
        :href="href"
        :isEmitter="href === '/models'"
      >
        <div class="base-link__wrapper" @click.stop="onMobileMenuItemClick(href)">
          <span>{{ text }}</span>
          <ArrowRightIcon />
        </div>
      </BaseLink>
      <BaseLink
        :key="profileHref"
        :href="profileHref"
      >
        <div class="base-link__wrapper" @click.stop="onMobileMenuItemClick('/profile')">
          <span>Профиль</span>
          <ArrowRightIcon />
        </div>
      </BaseLink>
    </div>
    <div
      class="menu-mobile menu-mobile__models"
      v-show="mobileMenuPage === 'models'"
      :class="[mobileMenuClass]"
    >
      <div class="menu-mobile__header" @click="mobileMenuPage = 'index'">
        <ArrowLeftIcon />
        Модели
      </div>
      <a
        class="menu-mobile__model-item"
        v-for="{ about, href, preview, title } in modelStore.modelsMenu"
        :key="href"
        :href="href"
      >
        <div class="model-item__text-wrapper">
          <span class="model-item__title">{{ title }}</span>
          <span class="model-item__description">{{ about }}</span>
        </div>
        <BaseImage
          :srcFunc="srcFunction(preview)"
          :width="maxWidth"
          :height="maxHeight"
          :alt="title"
          :sizes="imageSizes"
        />
      </a>
    </div>
  </header>
</template>

<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';
@import '@/assets/scss/mixins.scss';

.header-menu {
  height: 100%;
  display: flex;
  gap: 40px;

  @include screenEqAndLess1024 {
    gap: 10px;
  }
}

.header-menu__logged-out-text {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.header-menu__logged-out-text--gray-text {
  color: $colorLightBrand;
}

.header-menu__icons {
  display: flex;
  gap: 10px;
}

.header {
  height: $headerHeight;
  background-color: $colorWhite;
  width: 100%;
  display: flex;
  align-items: center;
  box-shadow: 0 0 10px rgba(48, 54, 58, 0.1);
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  -webkit-transition: top .3s linear, background .3s;
  transition: top .3s linear, background .3s;

  .container {
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }

  .menu-burger {
    display: flex;
    align-items: center;
  }

  .menu-mobile {
    display: none;
    padding-left: 0;
    padding-right: 0;

    :deep(.base-link) {
      border-bottom: 1px solid $colorPale;
      width: 100%;
      line-height: 19px;

      &:first-child {
        border-top: 1px solid $colorPale;
      }

      &:hover {
        background-color: $colorBright;
        border-bottom: 1px solid $colorBright;
        margin-top: -1px;
        padding-top: 1px;

        svg {
          color: $colorRed;
        }
      }
    }

    .base-link__wrapper {
      padding: 20.5px 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      :first-child {
        margin-left: 16px;
      }

      :last-child {
        margin-right: 16px;
      }
    }

    &--open {
      position: absolute;
      z-index: $zIndexMobileMenu;
      width: 100%;
      top: $headerHeight;
      height: calc(100vh - $headerHeight);
      height: calc((var(--vh, 1vh) * 100) - $headerHeight);
      display: flex;
      flex-direction: column;
      background-color: white;

      justify-content: flex-start;
      align-items: flex-start;
    }
  }
}

.menu-mobile__header {
  width: 100%;
  padding: 14px 21px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $colorBright;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  cursor: pointer;

  &::after {
    content: '';
  }

  &:hover {
    svg {
      color: $colorRed;
    }
  }
}

.menu-mobile__model-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid $colorPale;

  :deep(.base-image) {
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 20px;
  }

  &:hover {
    margin-top: -1px;
    padding-top: 1px;
    background-color: $colorBright;
    border-bottom: 1px solid $colorBright;
  }
}

.model-item__text-wrapper {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.model-item__title {
  font-weight: 300;
  font-size: 20px;
  line-height: 150%;
  margin-bottom: 8px;
}

.model-item__description {
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  color: $colorLightBrand;
  max-width: 150px;
  margin-bottom: 15px;
}
</style>
