import type { IMenuItem } from '@/types/Iterfaces';
import ModelsDropdown from '@/components/Header/ModelsDropdown.vue';

export const menuItems: IMenuItem[] = [
  {
    href: '/brand',
    text: 'О бренде',
  },
  {
    href: '/models',
    text: 'Модели',
    footerHref: '/models/c5/',
    footerText: 'OMODA C5',
    component: ModelsDropdown,
  },
  {
    href: '/news',
    text: 'Новости',
  },
  {
    href: '/gallery',
    text: 'Медиа',
  },
  {
    href: '/contacts',
    text: 'Контакты',
  },
];

export const unauthorisedMenuItems: IMenuItem[] = [
  {
    href: '/',
    text: 'Войти',
  },
  {
    href: '/register',
    text: 'Регистрация',
  },
];

export const footerData = {
  year: `© ${new Date().getFullYear()}`,
  companyName: ' АО «Чери Автомобили Рус»',
  legalInformation: {
    text: 'Правовая информация',
    link: 'https://omoda.ru/omoda/terms-conditions/',
  },
  socialLinks: [
    {
      name: 'telegram',
      link: 'https://t.me/omodarussia',
    },
    {
      name: 'vk',
      link: 'https://vk.com/omodarussia',
    },
    {
      name: 'ok',
      link: 'https://ok.ru/omodarussia',
    },
    {
      name: 'youtube',
      link: '',
    },
    {
      name: 'dzen',
      link: '',
    },
  ],
  notes: 'Фактические цвета серийных автомобилей могут отличаться от цветов, показанных на изображениях. Возможное сочетание цветов кузова, отделки, крыши, оборудование может быть опциональным. Информация на сайте не является публичной офертой (в соответствии со ст. 437, 494 ГК РФ). Подробности уточняйте у официальных дилеров ОМОДА. Информация на сайте www.omoda.ru/dealers. OMODA - ОМОДА, С5 - Ц5, CVT25 - Си-Ви-Ти-25, R18 - Эр-18, МсPherson - МакФерсон, SQRE4T15C - Эс-Кью-эР-Е-4-Tи-15-Си, Joy - Джой, Lifestyle - Лайфстайл, Ultimate - Ультимейт, Isofix - Исофикс, Follow me home - Фоллу Ми Хоум, AutoHold - АвтоХолд, Sport/Eco mode - Спорт/Эко-мод, Hands free - Хэндс Фри, Bluetooth - Блютуз, USB - Ю-Эс-Би.',
};

export const subscribePageData = {
  checkboxAppTitle: 'пресс-портала Omoda',
  policyPersonalDataLink: 'https://omoda.ru/omoda/terms-conditions/policy-personal-data/',
};

export const brandPageData = {
  banner_1: {
    title: 'OMODA',
  },
  banner_2: {
    title: 'o-universe',
    subtitle: 'Станьте свидетелями большого технологического взрыва, рождения новой вселенной — O-Universe.',
  },
  banner_3: {
    title: 'o-club',
    subtitle: 'O-People экспериментируют и не боятся рисковать, создают будущее прямо сейчас.',
  },
  banner_4: {
    title: 'o-life',
    subtitle:
      'В нашей новой вселенной больше всего ценится способность находить удивительное в привычных вещах, видеть новые возможности там, где другим это не под силу',
  },
  banner_5: {
    title: 'o-lab',
    subtitle: 'Если вы хотите создавать новую вселенную вместе с OMODA — следите за анонсами и событиями O-Lab!',
  },
  plate_1: {
    title: 'Будущее начинается вместе с OMODA',
    subtitle: 'Добро пожаловать в O-Universe — вселенную исключительного дизайна и инновационных технологий.',
    text_1:
      'O-Universe — это новое измерение, в котором возможно опередить привычное время, используя силу конструкторской мысли и технологий последнего поколения. Это мир, где мы получаем уникальный опыт и объединяем свои усилия, чтобы создавать нечто совершенно новое.',
    dark: true,
  },
  plate_2: {
    title: 'Сообщество единомышлен\u00ADников',
    subtitle: '',
    text_1:
      'Их объединяет невероятная энергия, желание создавать, непрерывно совершенствоваться и генерировать новые идеи. Что из них сбудется, мы узнаем совсем скоро — ведь будущее гораздо ближе, чем кажется.',
    dark: false,
  },
  plate_3: {
    title: '',
    subtitle:
      'В авангарде OMODA — наши амбассадоры, достижения и\u00A0рекорды которых на грани человеческих возможностей, за пределами достижимого.',
    text_1:
      'В числе первых амбассадоров — Анна Щербакова — обладательница золотой медали в одиночном фигурном катании на Олимпийских играх в Пекине, чемпионка мира и трехкратная чемпионка России, совершившая мировой рекорд на чемпионате Европы.',
    dark: false,
  },
  plate_4: {
    title: 'заглянуть за линию горизонта',
    subtitle: 'O-Life — это вещи и события, которые наполняют каждый день, позволяют видеть мир на 360 градусов',
    text_1:
      'O-People пробуют себя в искусстве, музыке, моде, разрушая внутренние барьеры и расширяя свой кругозор. Безлимитный пропуск к незабываемому опыту, который открывается перед владельцами OMODA и друзьями бренда. Присоединяйтесь к образу жизни O-People и становитесь участниками уникальный событий и активностей, которые специально для вас подготовила команда бренда.',
    dark: true,
  },
  plate_5: {
    title: 'переходите на новый уровень',
    subtitle:
      'Электрические автомобили, беспилотники и искусственный интеллект уже совсем скоро станут нашей реальностью.',
    text_1:
      'O-Lab — это серия онлайн лекций про будущее автомобильной индустрии. В качестве спикеров выступают лучшие автоэксперты, журналисты и блогеры. Вместе с нашим сообществом, мы будем проводить самые смелые инновационные эксперименты, решать загадки вселенной и исследовать фантастический мир будущего.',
    dark: false,
  },
  cards_1: [
    {
      id: 1,
      title: 'O-fashion',
      subtitle: 'Философия O-Universe определяет стремительный, футуристичный и одновременно минималистичный стиль.',
      text_1: 'O-Fashion — это яркие коллаборации с самыми прогрессивными российскими фэшн-дизайнерами, лимитированные капсульные коллекции реальной и цифровой одежды.',
      text_2: 'Первая дизайнерская капсула одежды O-Fashion будет выпущена уже в сентябре 2022 совместно с амбассадором OMODA Анной Щербаковой.',
      alt: 'O-fashion',
      img: 'card_1',
    },
    {
      id: 2,
      title: 'O-design',
      subtitle: 'Футуризм, авангардный подход к цвету и форме, дерзкие эксперименты с дизайном – в этом вся OMODA.',
      text_1: 'Дизайн, выдержанный в концепции Art in Motion, «искусство в движении», разработан международной командой дизайнеров под руководством Стива Юма.',
      text_2: 'O-Design выходит за пределы автомобиля и продолжается в лимитированных аксессуарах для владельцев OMODA, в специально выпущенных брендом NFT и далее — в бесконечности метавселенной.',
      alt: 'O-design',
      img: 'card_2',
    },
    {
      id: 3,
      title: 'O-art',
      subtitle: 'Digital art, метавселенная, NFT меняют парадигмы и демонстрируют, насколько бесконечно человеческое воображение.',
      text_1: 'Но что происходит, когда вселенная OMODA соединяется с безграничной фантазией художника? Правильно, автомобили превращаются в объект современного искусства.',
      text_2: 'Один из них будет представлен с 13 по 18 сентября 2022 года в Музее Москвы в рамках коллаборации OMODA и ярмарки молодого современного искусства Blazar.',
      alt: 'O-art',
      img: 'card_3',
    },
  ],
  cards_2: [
    {
      id: 1,
      title: 'o-tech',
      subtitle: 'Технологии трансформируют привычное понятие вождения и владения автомобилем',
      text_1: 'Наше представление об автомобиле и его роли в современной жизни находится на пороге глубоких изменений.  В направлении O-Tech мы расскажем про передовые разработки, которые уже находятся у вас под капотом и обеспечивают удовольствие от вождения, а также покажем, как вывести опыт пользования OMODA на новый, космический уровень.',
      alt: 'o-tech',
      img: 'card_4',
    },
    {
      id: 2,
      title: 'o-space',
      subtitle: 'Следите за новостями, чтобы «поймать» OMODA С5 в своем городе!',
      text_1: 'O-Space – пространство, где вы лично сможете увидеть OMODA C5, испытать эстетическое удовольствие от автомобиля, ощутить силу инженерной и дизайнерской мысли. Именно здесь начинается физическое знакомство с автомобилем – как и блуждающие вселенные, автомобиль будет постоянно перемещаться, оказываясь в совершенно нестандартных локациях по всей России.',
      alt: 'o-space',
      img: 'card_5',
    },
  ],
  imageSizes_cards_1: [
    {
      imageWidth: 420,
      screenWidth: 420,
    },
    {
      imageWidth: 1024,
      screenWidth: 768,
    },
    {
      imageWidth: 420,
      screenWidth: 1024,
    },
  ],
  imageSizes_cards_2: [
    {
      imageWidth: 420,
      screenWidth: 420,
    },
    {
      imageWidth: 1024,
      screenWidth: 768,
    },
    {
      imageWidth: 1024,
      screenWidth: 1024,
    },
  ],
};

export const mainSiteLink = 'https://omoda.ru/';
export const clubLink = 'https://t.me/omoda5club';
