import type { MEDIA_TYPE } from '@/types/media';

export interface IFile {
  id: number;
  file: string;
  title: string;
  size: number;
  mime_type: string;
  icon: string;
  size_human: string;
  type: MEDIA_TYPE.IMAGES | MEDIA_TYPE.VIDEOS | MEDIA_TYPE.DOCS;
  in_basket: boolean;
}
export class FileClass {
  id: number;
  file: string;
  title: string;
  size: number;
  mimeType: string;
  icon: string;
  sizeHuman: string;
  type: MEDIA_TYPE.IMAGES | MEDIA_TYPE.VIDEOS | MEDIA_TYPE.DOCS;
  inBasket: boolean;
  isPendingItemData?: boolean;

  constructor(file: IFile) {
    this.id = file.id;
    this.file = file.file;
    this.title = file.title;
    this.size = file.size;
    this.mimeType = file.mime_type;
    this.icon = file.icon;
    this.sizeHuman = file.size_human;
    this.type = file.type;
    this.inBasket = file.in_basket;
    this.isPendingItemData = false;
  }
}
